@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Mulish&display=swap);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 62.5%;
  color: #21334f;
  /* scroll-behavior: smooth; */
}

body {
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem!important;
}

.home_parent_wrapper {
  max-width: 1240px;
  margin: auto;
}

@media screen and (max-width: 1300px) {
  .home_parent_wrapper {
    max-width: 1150px;
    margin: auto;
  }
}

@media screen and (max-width: 1150px) {
  .home_parent_wrapper {
    max-width: none;
    margin: 0 16px;
  }
}

#scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

#scrollbar::-webkit-scrollbar {
  display: none;
}

.font-inter {
  font-family: "Inter", sans-serif!important;
}

img {
  width: 100%;
}

textarea,
select,
input {
  border: none;
  outline: none;
}

input,
textarea {
  -webkit-appearance: none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input[type="date"]:before {
  color: rgba(33, 51, 79, 0.8);
}

input[type="date"]:after {
  color: #21334f;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-font {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
}
.form-font::-webkit-input-placeholder {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 20px;
  color: rgba(33, 51, 79, 0.8);
}
.form-font::placeholder {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 20px;
  color: rgba(33, 51, 79, 0.8);
}

.form-label {
  display: block;
  color: #21334f;
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.2rem;
}

.form-error {
  color: #ef5350;
  font-size: 1.2rem;
  display: block;
  max-width: 310px;
}
.form-btn {
  border: none;
}
.form-btn:hover,
.form-btn:focus {
  background: #34a853;
}

.black-text {
  color: #21334f;
}

.green-text {
  color: #34a853;
}

.spinner {
  margin: 0 auto;
}
.btn-comp:hover,
.btn-comp:focus {
  background: #34a853;
  color: white;
  text-decoration: none;
}

#nprogress .bar {
  background: #34a853!important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #34a853, 0 0 5px #34a853 !important;
}

#nprogress .spinner-icon {
  border-top-color: #34a853 !important;
  border-left-color: #34a853 !important;
}

